import React, {useCallback, useEffect, useState} from 'react';
import {Box, Divider, FormControlLabel, Grid, RadioGroup} from "@mui/material";
import PropTypes from "prop-types";
import Radio from "@mui/material/Radio";
import config from "../../../config/config";
import {useStore} from "../../../store/useStore";

const RendererQuestionnaire = (props) => {
    const { state } = useStore();

    const [mounted, setMounted] = useState(false);
    const [values, setValues] = useState({});
    const [answered, setAnswered] = useState(false);
    const [currentQuestions, setCurrentQuestions] = useState([]);
    const questionAmount = (props.element.questions.length > props.element.questionsPerPage) ? Number(props.element.questionsPerPage) : props.element.questions.length;

    const getCurrentQuestionAmount = useCallback(() => {
        const answerKeys = Object.keys(props.answers);
        const allQuestions = props.element.questions;
        const totalRemainingQuestions = allQuestions.length - answerKeys.length;

        return (totalRemainingQuestions > questionAmount) ? questionAmount : totalRemainingQuestions;
    }, [props.answers, props.element.questions, questionAmount]);

    const getCurrentQuestions = useCallback(() => {
        const answerKeys = Object.keys(props.answers);
        const allQuestions = props.element.questions;
        const pickableQuestions = (answerKeys) ? allQuestions.filter((item) => !answerKeys.includes(item.id)) : allQuestions;
        const selectedQuestions = [];

        for (let i = 0; i < getCurrentQuestionAmount(); i++) {
            const selectedQuestion = pickableQuestions.splice(0, 1)[0];
            selectedQuestion.index = allQuestions.indexOf(selectedQuestion);

            selectedQuestions.push(selectedQuestion);
        }

        return selectedQuestions;
    }, [props.element.questions, props.answers, getCurrentQuestionAmount]);

    const getTranslation = useCallback(() => {
        if (props.element?.translations) {
            const translation = props.element.translations.find(translation => translation.language === state.localeIRI);

            if (translation) {
                return translation;
            }
        }

        return null;
    }, [props.element.translations, state.localeIRI]);

    useEffect(() => {
        if (!mounted) {
            setMounted(true);

            const selectedQuestions = getCurrentQuestions();

            if (selectedQuestions.length) {
                props.setReadyForNextPage(false);
            }

            setCurrentQuestions(selectedQuestions);
        }
    }, [mounted, props, getCurrentQuestions]);

    const isLastQuestions = useCallback(() => {
        const answerKeys = Object.keys(props.answers);
        const allQuestions = props.element.questions;

        return (answerKeys.length + getCurrentQuestionAmount()) >= allQuestions.length;
    }, [props.answers, props.element.questions, getCurrentQuestionAmount]);

    const isAllAnswered = useCallback((newValues) => {
        if (getCurrentQuestionAmount() === Object.keys(newValues).length && !answered) {
            if (isLastQuestions()) {
                props.setReadyForNextPage(true);
            }

            setAnswered(true);
            props.answered();
        }
    }, [props, answered, isLastQuestions, getCurrentQuestionAmount]);

    const handleChange = useCallback((elementId, optionIndex) => {
        let newValues = {
            ...values,
            [elementId]: optionIndex
        };

        setValues(newValues);
        props.onChangeHandler(newValues);

        isAllAnswered(newValues);
    }, [values, props, isAllAnswered]);

    const isSelfPin = useCallback(() => {
        return state.pinRessource?.threeSixtyRole && state.pinRessource.threeSixtyRole === '/api/three_sixty_roles/1';
    }, [state.pinRessource?.threeSixtyRole]);

    const createBars = (amount) => {
        let bars = [];

        for (let i = 0; i < amount; i++) {
            bars.push(<Box sx={{borderBottom: "solid", mb: 0.25}} key={i}/>);
        }

        return bars;
    }

    const getElementQuestionOptionTranslation = useCallback((index) => {
        if (state.localeIRI !== config.defaultLocale) {
            const translation = getTranslation();

            if (translation && translation.options) {
                return translation.options[index];
            }
        }

        return props.element.options[index];
    }, [props.element.options, state.localeIRI, getTranslation]);

    const HeaderOptions = () => {
        let optionHeader = props.element.options.map((element, index) => {
            return <Grid item xs key={index} className={'text-align-center'}>
                    <span title={element}>
                        {
                            (
                                (index === 0 || index === props.element.options.length - 1) || props.element.showAllLabels === true
                            )
                            &&
                            <Box mb={2}>{getElementQuestionOptionTranslation(index)}</Box>
                        }
                        {createBars(index + 1)}
                    </span>
            </Grid>
        });

        if (props.element?.isCantAnswer && !isSelfPin()) {
            optionHeader.push(
                <Grid item xs key={'test'} className={'text-align-center'}>
                    <span title={"test"}>Can't answer</span>
                </Grid>
            );
        }

        return optionHeader;
    };

    const radioItem = useCallback((value) => {
        return (
            <FormControlLabel key={'radio_' + value} value={value} control={<Radio />} label={getElementQuestionOptionTranslation(value)} />
        );
    }, [getElementQuestionOptionTranslation]);

    const RadioItems = useCallback((_props) => {
        const content = [];
        let lastItemsAt = 0;

        for (let i = 0; i < _props.elementOptions.length; i++) {
            if ((i + 1) % 4 === 0) {
                lastItemsAt = i;
                for (let i2 = i - 3; i2 <= i; i2++) {
                    content.push(radioItem(i2));
                }
            } else if (i === _props.elementOptions.length - 1) {
                const restCount = 4 - ((i + 1) % 4);
                for (let i3 = 1; i3 <= restCount; i3++) {
                    content.push(<Grid item xs={3} key={'filler_' + i3}></Grid>)
                }

                for (let i2 = lastItemsAt + 1; i2 <= i; i2++) {
                    content.push(radioItem(i2));
                }
            }
        }

        return content;
    }, [radioItem]);

    const ElementQuestionOptionsMobile = useCallback((_props) => {
        return (
            <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={values[_props.questionId]}
                onChange={(event) => handleChange(_props.questionId, event.target.value)}
            >
                <RadioItems elementOptions={_props.elementOptions}/>
            </RadioGroup>
        );
    }, [handleChange, values]);

    const ElementQuestionOptions = useCallback((props) => {
        const options = (
            props.elementOptions.map((option, optionIndex) => {
                return (
                    <Grid item xs key={optionIndex} className={'text-align-center'}>
                        <Radio
                            checked={values[props.questionId] === optionIndex}
                            onChange={() => handleChange(props.questionId, optionIndex)}
                            value={optionIndex}
                            name={`questionnaire--radio-${props.questionId}`}
                            title={getElementQuestionOptionTranslation(optionIndex)}
                            inputProps={{'aria-label': getElementQuestionOptionTranslation(optionIndex)}}
                        />
                    </Grid>
                )
            })
        );

        if (props.isCantAnswer && !isSelfPin()) {
            options.push(
                <Grid item xs key={'cant_answer'} className={'text-align-center'}>
                    <Radio
                        checked={values[props.questionIndex] === -1}
                        onChange={() => handleChange(props.questionIndex, -1)}
                        value={-1}
                        name={`questionnaire--radio-${props.questionIndex}`}
                        title={getElementQuestionOptionTranslation(-1)}
                        inputProps={{'aria-label': getElementQuestionOptionTranslation(-1)}}
                    />
                </Grid>
            );
        }

        return options;
    }, [values, handleChange, getElementQuestionOptionTranslation, isSelfPin]);

    const getQuestionLabelTranslation = useCallback((index, key) => {
        if (state.localeIRI !== config.defaultLocale) {
            const translation = getTranslation();

            if (translation && translation.options) {
                return translation.questions[key];
            }

            return props.element.questions[index].label;
        } else {
            return props.element.questions[index].label;
        }
    }, [props.element.questions, state.localeIRI, getTranslation]);

    const ElementQuestions = () => {
        return currentQuestions.map((question) => {
            const questionIndex = question.index;

            return (
                <React.Fragment key={questionIndex}>
                    <Grid item xs={12}>
                        <Grid container
                              className={'questionnaire--row'}
                              alignItems={"center"}
                              rowSpacing={2}
                              key={questionIndex}>
                            <Grid item xs={12} md={6}>
                                {getQuestionLabelTranslation(questionIndex, question.id)}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{display: {xs: 'block', md: 'none'}}}>
                                    <Grid container alignItems={"flex-end"} spacing={1}>
                                        <ElementQuestionOptionsMobile
                                            questionId={question.id}
                                            elementOptions={props.element.options}
                                        />
                                    </Grid>
                                </Box>
                                <Box sx={{display: {xs: 'none', md: 'block'}}}>
                                    <Grid container alignItems={"center"} spacing={1}>
                                        <ElementQuestionOptions
                                            questionId={question.id}
                                            elementOptions={props.element.options}
                                            isCantAnswer={props.element?.isCantAnswer}
                                        />
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                </React.Fragment>
            )
        });
    };

    return (
        <div className={'questionnaire'}>
            <Box sx={{pb: 2, display: { xs: 'none', md: 'block' }}}>
                <Grid container className={'questionnaire--header'} rowSpacing={2}>
                    <Grid item xs={false} md={6}/>
                    <Grid item xs={12} md={6}>
                        <Grid container rowSpacing={2} spacing={1} alignItems={"flex-end"}>
                            <HeaderOptions/>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider/>
            </Box>
            <Box>
                <Grid container rowSpacing={2} className={'vertical-align-middle'}>
                    <ElementQuestions/>
                </Grid>
            </Box>
        </div>
    );
};

RendererQuestionnaire.propTypes = {
    element: PropTypes.object.isRequired,
    onChangeHandler: PropTypes.func.isRequired,
    answered: PropTypes.func.isRequired,
    answers: PropTypes.object.isRequired,
    setReadyForNextPage: PropTypes.func.isRequired,
}

export default RendererQuestionnaire;